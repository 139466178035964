import React from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { Headline, Body, Hr } from '@sumup/circuit-ui';
import { spacing } from '@sumup/circuit-ui/legacy';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { MergeTag } from '@ninetailed/experience.js-next';

import EmbeddedVariable from '../components/EmbeddedVariable';
import EmbeddedRichtext from '../components/EmbeddedRichtext';
import EmbeddedImage from '../components/EmbeddedImage';
import { getFields, getContentType } from '../util';
import ColumnAlign from '../components/ColumnAlign';
import { ImagePreset } from '../../ColumnLayout/interfaces';

import { TYPES } from '~/shared/constants/sections';
import slugify from '~/shared/util/slugify';
import Anchor from '~/shared/components/Anchor';
import { NINETAILED_IS_ENABLED } from '~/shared/services/ninetailed/constants';

const headlineStyles = ({
  theme,
  hasSplitLayout,
}: {
  theme?: Theme;
  hasSplitLayout: boolean;
}) => css`
  margin-top: ${hasSplitLayout ? '0' : theme.spacings.giga};
  margin-bottom: ${theme.spacings.mega};
`;

const StyledHeadline = styled(Headline)(headlineStyles);

type RenderNodeType = {
  imagePreset?: Record<string, string>;
  numberOfColumns?: number;
};

const StyledBodyBold = styled(Body)(css`
  font-size: inherit !important;
`);

const StyledBodyItalic = styled(Body)(css`
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: italic;
`);

const getImagePresetProps = ({
  fields,
  imagePreset = {},
}: {
  fields: Record<string, any>;
  imagePreset?: ImagePreset;
}) => {
  const { align } = fields;

  const { imagePresetSize, imagePresetAlignment } = imagePreset;
  const mediaAlignment = imagePresetAlignment || align;

  return {
    mediaAlignment,
    imagePresetSize,
  };
};

export const renderNode = ({
  imagePreset = {},
  numberOfColumns,
}: RenderNodeType = {}) => ({
  [BLOCKS.HEADING_2]: (node, children) => (
    <StyledHeadline
      as={node.as}
      size="one"
      id={slugify(children)}
      hasSplitLayout={node.hasSplitLayout}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.HEADING_3]: (node, children) => (
    <StyledHeadline
      as={node.as || 'h3'}
      size="two"
      id={slugify(children)}
      hasSplitLayout={node.hasSplitLayout}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.HEADING_4]: (node, children) => (
    <StyledHeadline
      as={node.as}
      size="four"
      id={slugify(children)}
      hasSplitLayout={node.hasSplitLayout}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.HEADING_5]: (node, children) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Body size="one" css={spacing({ bottom: 'mega' })}>
      {children}
    </Body>
  ),
  [BLOCKS.PARAGRAPH]: (node, children) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Body size="one" css={spacing({ bottom: 'mega' })}>
      {children}
    </Body>
  ),
  [BLOCKS.QUOTE]: (node, children) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Body variant="quote" size="one" css={spacing({ bottom: 'mega' })}>
      {children}
    </Body>
  ),
  [BLOCKS.HR]: () => <Hr />,
  [INLINES.HYPERLINK]: (node, children) => (
    <Anchor href={node.data.uri}>{children}</Anchor>
  ),
  [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
    const contentType = getContentType(node);
    const fields = getFields(node);
    const { imagePresetSize, mediaAlignment } = getImagePresetProps({
      fields,
      imagePreset,
    });

    switch (contentType) {
      case 'genericImage':
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          <ColumnAlign align={mediaAlignment} imagePresetSize={imagePresetSize}>
            <EmbeddedImage
              {...fields}
              contentType={TYPES.COLUMN_LAYOUT}
              shouldUseDefaultSizes={numberOfColumns === 1}
            >
              {children}
            </EmbeddedImage>
          </ColumnAlign>
        );
      case 'embeddedRichtext':
        return <EmbeddedRichtext {...fields}>{children}</EmbeddedRichtext>;

      default:
        return null;
    }
  },
  [INLINES.EMBEDDED_ENTRY]: (node, children) => {
    if (
      node?.data?.target?.contentType === 'nt_mergetag' &&
      node?.data?.target?.nt_mergetag_id &&
      NINETAILED_IS_ENABLED
    ) {
      return <MergeTag id={node.data.target.nt_mergetag_id} />;
    }

    const contentType = getContentType(node);
    const fields = getFields(node);

    const { imagePresetSize, mediaAlignment } = getImagePresetProps({
      fields,
      imagePreset,
    });

    switch (contentType) {
      case 'embeddedVariable':
        return <EmbeddedVariable {...fields} />;

      case 'embeddedStrikethroughText':
        return <s>{fields.content}</s>;

      case 'embeddedRichtext':
        return <EmbeddedRichtext {...fields}>{children}</EmbeddedRichtext>;

      case 'genericImage':
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          <ColumnAlign align={mediaAlignment} imagePresetSize={imagePresetSize}>
            <EmbeddedImage
              {...fields}
              contentType={TYPES.COLUMN_LAYOUT}
              shouldUseDefaultSizes={numberOfColumns === 1}
            >
              {children}
            </EmbeddedImage>
          </ColumnAlign>
        );
      default:
        return null;
    }
  },
});

export const renderMark = {
  [MARKS.BOLD]: (text) => (
    <StyledBodyBold
      as="strong"
      size="one"
      variant="highlight"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      css={spacing({ bottom: 'mega' })}
    >
      {text}
    </StyledBodyBold>
  ),
  [MARKS.ITALIC]: (text) => (
    <StyledBodyItalic
      as="em"
      size="one"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      css={spacing({ bottom: 'mega' })}
    >
      {text}
    </StyledBodyItalic>
  ),
};
