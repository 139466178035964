import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ALIGNMENT, PRESET_IMAGE_SIZE } from '~/shared/constants';

const { RIGHT, LEFT, CENTER, WIDE } = ALIGNMENT;

const baseStyles = ({ theme }) => css`
  clear: both;
  margin-top: ${theme.spacings.tera};
  margin-bottom: ${theme.spacings.tera};
  text-align: center;
`;

const sideAlignedStyles = ({ align }) =>
  (align === LEFT || align === RIGHT) &&
  css`
    text-align: left;
  `;

const sideAlignedSizes = ({ theme, align, imagePresetSize }) =>
  !imagePresetSize &&
  (align === LEFT || align === RIGHT) &&
  css`
    ${theme.mq.kilo} {
      width: calc(50% + ${theme.spacings.peta} / 2);
    }
    ${theme.mq.mega} {
      width: calc(26.66% * 2 + ${theme.spacings.peta});
    }
  `;

const centerStyles = ({ align }) =>
  align === CENTER &&
  css`
    margin-left: auto;
    margin-right: auto;
  `;

const rightStyles = ({ theme, align, imagePresetSize }) =>
  !imagePresetSize &&
  align === RIGHT &&
  css`
    ${theme.mq.kilo} {
      padding-left: ${theme.spacings.peta};
    }
  `;

const leftStyles = ({ theme, align, imagePresetSize }) =>
  !imagePresetSize &&
  align === LEFT &&
  css`
    ${theme.mq.kilo} {
      padding-right: ${theme.spacings.peta};
    }
  `;

const wideStyles = ({ theme, align, imagePresetSize }) =>
  !imagePresetSize &&
  align === WIDE &&
  css`
    ${theme.mq.mega} {
      margin-right: -${theme.spacings.kilo};
      margin-left: -${theme.spacings.kilo};
      width: calc(100% + ${theme.spacings.kilo} * 2);
    }
  `;

const skipsMarginsStyles = ({ skipsMargins }) =>
  skipsMargins &&
  css`
    margin-top: 0;
    margin-bottom: 0;
  `;

const presetImageIconStyles = ({ theme, imagePresetSize }) =>
  imagePresetSize === PRESET_IMAGE_SIZE.ICON &&
  css`
    width: ${theme.spacings.zetta};
  `;

const presetImageMediumStyles = ({ imagePresetSize }) =>
  imagePresetSize === PRESET_IMAGE_SIZE.MEDIUM &&
  css`
    width: 70%;
  `;

const presetImageFullStyles = ({ imagePresetSize }) =>
  imagePresetSize === PRESET_IMAGE_SIZE.FULL &&
  css`
    width: 100%;
  `;

const ColumnAlign = styled('div')(
  baseStyles,
  sideAlignedStyles,
  sideAlignedSizes,
  centerStyles,
  rightStyles,
  leftStyles,
  wideStyles,
  skipsMarginsStyles,
  presetImageIconStyles,
  presetImageMediumStyles,
  presetImageFullStyles,
);

ColumnAlign.RIGHT = RIGHT;
ColumnAlign.LEFT = LEFT;
ColumnAlign.CENTER = CENTER;
ColumnAlign.WIDE = WIDE;

ColumnAlign.propTypes = {
  align: PropTypes.oneOf([RIGHT, LEFT, CENTER, WIDE]),
};

ColumnAlign.defaultProps = {
  align: CENTER,
};

/**
 * @component
 */
export default ColumnAlign;
