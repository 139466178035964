import fromPairs from 'lodash/fp/fromPairs';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import toArray from 'lodash/fp/toArray';
import { getAll } from 'es-cookie';
import { elb } from '@elbwalker/walker.js';

import { LeadContext } from '../../services/interfaces';

import { LEAD_RECEIVERS_CONTENTFUL_OPTIONS } from './constants';
import { Step } from './types';

import {
  createLead,
  getJSONFromLocalStorage,
  saveFormSubmissionToLocalStorage,
  saveUserDataToLocalStorage,
  getStringFromLocalStorage,
} from '~/domains/sales-leads/services/client';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import * as Analytics from '~/shared/services/analytics';
import {
  createRichTextDocument,
  createRichTextParagraph,
  createRichTextText,
} from '~/shared/util/rich-text';
import { USER_DATA_KEY_CONVERTFLOW } from '~/domains/sales-leads/services/client/constants';
import { GA_ID_COOKIE_NAME } from '~/shared/services/optimizely/constants';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import { RichTextPropType } from '~/shared/types/shared';

// eslint-disable-next-line no-shadow
export enum Actions {
  INITIAL = 'INITIAL',
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  INIT_FROM_LOCAL_STORAGE = 'INIT_FROM_LOCAL_STORAGE',
  THANKYOU = 'THANKYOU',
  IS_LOADING = 'IS_LOADING',
  RETURNING = 'RETURNING',
}

// eslint-disable-next-line no-shadow
export enum Stages {
  NORMAL = 'NORMAL',
  THANKYOU = 'THANKYOU',
  RETURNING = 'RETURNING',
}

export const buildInitialState = (steps) => ({
  current: 1,
  total: steps?.length,
  stage: Stages.NORMAL,
});

export const createAction = (type: Actions) => ({
  type,
});

export const createNextAction = (stepData) => ({
  type: Actions.NEXT,
  payload: stepData,
});

export const createIsLoadingAction = (isLoading = false) => ({
  type: Actions.IS_LOADING,
  isLoading,
});

export function reducer(
  state,
  {
    type,
    isLoading,
    payload,
    step,
    stage,
    fileDownloaded,
  }: {
    type: Actions;
    isLoading?: boolean;
    payload?: Record<string, any>;
    step?: Step;
    stage?: Stages;
    fileDownloaded?: any;
  },
) {
  switch (type) {
    case Actions.INITIAL: {
      return { ...state, stage: Stages.NORMAL, current: 1 };
    }
    case Actions.NEXT: {
      const current =
        state.current < state.total ? state.current + 1 : state.current;
      const form = { ...state.form, ...payload };

      return {
        ...state,
        form,
        current,
      };
    }
    case Actions.PREVIOUS:
      return {
        ...state,
        current: state.current > 1 ? state.current - 1 : 1,
      };
    case Actions.INIT_FROM_LOCAL_STORAGE:
      return {
        ...state,
        current: step <= state.total ? step : 1,
        stage,
        form: { ...state.form, ...payload },
        loadingFromLocalStorageComplete: true,
        fileDownloaded,
      };
    case Actions.IS_LOADING:
      return {
        ...state,
        isLoading,
      };
    case Actions.THANKYOU:
      return { ...state, stage: Stages.THANKYOU, isLoading: false };
    case Actions.RETURNING:
      return { ...state, stage: Stages.RETURNING, isLoading: false };
    default:
      return state;
  }
}

const KEYS = {
  CURRENT: '%CURRENT%',
  TOTAL: '%TOTAL%',
};

const defaultProgressBarContent = createRichTextDocument([
  createRichTextParagraph([
    createRichTextText(`Step ${KEYS.CURRENT} of ${KEYS.TOTAL}`),
  ]),
]) as RichTextPropType;

/**
 * It will go ONLY through the first paragraph and replace the content of it to put the dynamic step information. **Immutable**
 * @param {RichTextDocument} progressBarContent
 * @param {StepState} state
 * @returns new progress bar content
 */
export function processProgressBarLabel(
  state,
  progressBarContent = defaultProgressBarContent,
) {
  const firstParapgrahContents = progressBarContent.content[0]?.content || [];
  return createRichTextDocument([
    createRichTextParagraph(
      firstParapgrahContents.map((firstParagraphContent) => {
        // eslint-disable-next-line no-param-reassign
        const stepValue = firstParagraphContent.value
          .replace(KEYS.CURRENT, state.current)
          .replace(KEYS.TOTAL, state.total);

        return {
          ...firstParagraphContent,
          value: stepValue,
        };
      }),
    ),
  ]);
}
const SPAN_VALUES = {
  'Full width': 'span 6',
  '1/2': 'span 3',
  '1/3': 'span 2',
};

export function getSpanFromFieldWidth(size = []) {
  return SPAN_VALUES[size[0]] || SPAN_VALUES['Full width'];
}

const buildStepParametersForTracking = (
  event,
  formId,
  formName,
  formContentType,
  step,
) => ({
  event,
  formId,
  formName,
  formContentType,
  componentContentEntryId: step?.id || 'unknown-id',
  componentContentEntryName: step?.name || 'step-name',
  componentContentType: step?.contentType || 'formStep',
});

export function trackStep(
  trackingId,
  event,
  formId,
  formName,
  formContentType,
  step,
  optimizelyEvent,
) {
  const customParameters = buildStepParametersForTracking(
    event,
    formId,
    formName,
    formContentType,
    step,
  );

  const analyticsPayload = {
    event,
    target: 'Mkt_Web',
    action: trackingId,
    ...customParameters,
  };

  Analytics.sendEvent(analyticsPayload);

  sendNinetailedEvent(trackingId);

  elb('button clicked', {
    button_description: trackingId,
  });

  if (!isEmpty(optimizelyEvent)) {
    OptimizelyFullStack.trackEvents(optimizelyEvent);
  }
}

export function prepareLead(
  formEl,
  state,
  leadReceiver,
  formId,
  locale,
  finalStep = false,
) {
  const formData = new FormData(formEl);
  const stepBody = fromPairs(toArray(formData) as any);
  const body = {
    ...state.form,
    ...stepBody,
  };

  const leadContext = {} as LeadContext;

  if (leadReceiver === LEAD_RECEIVERS_CONTENTFUL_OPTIONS.MARKETING_CLOUD) {
    const cookiesObject = getAll();
    const gaId = get(GA_ID_COOKIE_NAME, cookiesObject) || '';
    const multistepFormId = `${formId}${gaId}`;
    body.multistep_id = multistepFormId;
    body.multistep_completed = !!finalStep;

    leadContext.leadReceiver = 'marketingcloud';
    leadContext.marketingCloudTable = 'generic';
    leadContext.locale = locale;
  }

  if (
    leadReceiver ===
      LEAD_RECEIVERS_CONTENTFUL_OPTIONS.TILLER_CARD_READER_LEADS ||
    leadReceiver === LEAD_RECEIVERS_CONTENTFUL_OPTIONS.TILLER
  ) {
    leadContext.leadReceiver = 'tiller';
    leadContext.tillerHandler =
      leadReceiver ===
      LEAD_RECEIVERS_CONTENTFUL_OPTIONS.TILLER_CARD_READER_LEADS
        ? 'card_reader_leads'
        : 'default';
  }

  return { body, leadContext, stepBody };
}

export function submitForm(
  formEl,
  state,
  leadReceiver,
  dispatch,
  formId,
  isDownloadStep,
  locale,
) {
  const { body, leadContext } = prepareLead(
    formEl,
    state,
    leadReceiver,
    formId,
    locale,
    true,
  );

  dispatch(createIsLoadingAction(true));

  createLead(leadContext, body).then((response: Response) => {
    if (response.ok) {
      const data = updateDataIfDownloadWasTriggered(isDownloadStep, {
        step: state.current,
        stage: Actions.THANKYOU,
      });
      saveDataToLocalStorage(formId, body, data);
      dispatch(createAction(Actions.THANKYOU));
    } else {
      dispatch(createIsLoadingAction(false));
    }
  });
}

export function submitStep(
  formEl,
  state,
  leadReceiver,
  dispatch,
  currentStep,
  isDownloadStep,
  formId,
  formName,
  formContentType,
  nextButtonTrackingId,
  nextButtonOptimizelyFullStackClickEvents,
  locale,
) {
  const { body, stepBody, leadContext } = prepareLead(
    formEl,
    state,
    leadReceiver,
    formId,
    locale,
    false,
  );
  if (currentStep?.consentToSendStepInformation) {
    // fire and forget so it doesn't impact UX
    createLead(leadContext, body);
  }
  dispatch(createNextAction(stepBody));

  const data = updateDataIfDownloadWasTriggered(isDownloadStep, {
    step: state.current + 1,
  });
  saveDataToLocalStorage(formId, body, data);

  trackStep(
    nextButtonTrackingId,
    'interaction',
    formId,
    formName,
    formContentType,
    currentStep,
    nextButtonOptimizelyFullStackClickEvents,
  );
}

export function updateDataIfDownloadWasTriggered(isDownloadStep, rawData) {
  return isDownloadStep ? { ...rawData, fileDownloaded: true } : rawData;
}

export function saveDataToLocalStorage(formId, data, formDetails) {
  saveFormSubmissionToLocalStorage(formId, data, formDetails);
  saveUserDataToLocalStorage(data);
}

export function getDataFromLocalStorage(formId, dispatch) {
  const currentFormData = getJSONFromLocalStorage(formId);

  const userDataFromConvertflow = getStringFromLocalStorage(
    USER_DATA_KEY_CONVERTFLOW,
  );

  // If there is more than one email available in local storage, the existing user data will prevail over the email from Convertflow
  const getPayload = (currentData, convertflowData) => {
    if (currentData.fields?.email) {
      return currentData.fields;
    }
    if (convertflowData) {
      return { ...currentData.fields, email: convertflowData };
    }
    return currentData.fields;
  };

  dispatch({
    type: Actions.INIT_FROM_LOCAL_STORAGE,
    step: currentFormData.step || 1,
    stage: currentFormData.stage || Stages.NORMAL,
    payload: getPayload(currentFormData, userDataFromConvertflow),
    fileDownloaded: currentFormData.fileDownloaded || false,
  });
}

export function openFileByUrl(url) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
