import React, {
  useCallback,
  useReducer,
  useMemo,
  useContext,
  useEffect,
  useState,
} from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { ProgressBar, Button } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';

import Form from '../Form';

import {
  Actions,
  buildInitialState,
  createAction,
  reducer,
  Stages,
  processProgressBarLabel,
  getSpanFromFieldWidth,
  trackStep,
  submitForm,
  submitStep,
  getDataFromLocalStorage,
  openFileByUrl,
} from './MultistepFormService';
import { LEAD_RECEIVERS_CONTENTFUL_OPTIONS } from './constants';
import { Step } from './types';

import { ALIGNMENT } from '~/shared/constants';
import RichText from '~/shared/components/RichText';
import * as MultistepFormRichText from '~/shared/components/RichText/configs/multistep-form';
import dataSelector from '~/shared/util/data-selector';
import SiteContext from '~/shared/providers/SiteContext';
import Link from '~/shared/components/Link';
import SectionHeader from '~/shared/components/SectionHeader';
import { RichTextPropType } from '~/shared/types/shared';
import { Alignment } from '~/shared/components/ColumnLayout/interfaces';
import { RichtextColumnProps } from '~/shared/components/ColumnLayout/components/RichtextColumn/types';

const DATA_SELECTOR = 'multistepForm';

const sectionHeaderStyles = ({ theme }: { theme: Theme }) => css`
  margin-bottom: ${theme.spacings.mega};
  padding: ${theme.spacings.tera} ${theme.spacings.tera} 0
    ${theme.spacings.tera};

  ${theme.mq.untilMega} {
    padding: ${theme.spacings.tera} ${theme.spacings.kilo} 0
      ${theme.spacings.kilo};
  }
`;

const StyledSectionHeader = styled(SectionHeader)(sectionHeaderStyles);

const containerStyles = ({
  theme,
  stackOnTablet,
}: {
  theme?: Theme;
  stackOnTablet: boolean;
}) => css`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  ${theme.mq.untilKilo} {
    flex-direction: column;
  }
  ${stackOnTablet &&
  css`
    ${theme.mq.untilMega} {
      flex-direction: column;
    }
  `}
`;
const Container = styled('div')(containerStyles);

const contentSectionStyles = ({
  theme,
  hideOnMobile,
}: {
  theme?: Theme;
  hideOnMobile: boolean;
}) => css`
  padding: ${theme.spacings.tera};
  min-width: 50%;
  max-width: 50%;
  ${theme.mq.untilMega} {
    max-width: unset;
    padding: ${theme.spacings.tera} ${theme.spacings.kilo};
    padding-bottom: 0;
    flex-direction: column;
  }

  ${hideOnMobile &&
  css`
    ${theme.mq.untilKilo} {
      display: none;
    }
  `}
`;
const ContentSection = styled('div')(contentSectionStyles);

const formSectionSectionStyles = ({
  theme,
  stackOnTablet,
}: {
  theme?: Theme;
  stackOnTablet?: boolean;
}) => css`
  padding: ${theme.spacings.tera};
  max-width: 50%;
  flex: auto;
  ${theme.mq.untilMega} {
    max-width: unset;
    padding: ${theme.spacings.tera} ${theme.spacings.kilo};
    min-width: 50%;
    ${stackOnTablet &&
    css`
      min-width: unset;
    `}
  }
`;
const FormSection = styled('div')(formSectionSectionStyles);

const thankyouContainerStyles = () => css`
  text-align: center;
`;
const ThankyouContainer = styled('div')(thankyouContainerStyles);

const progressBarStyles = ({ theme }: { theme?: Theme }) => css`
  flex: 1;
  margin-bottom: ${theme.spacings.mega};
`;
const StyledProgressBar = styled(ProgressBar)(progressBarStyles);

const progressBarWrapperStyles = ({ theme }: { theme?: Theme }) => css`
  justify-content: flex-end;
  display: flex;
  gap: ${theme.spacings.byte};
  margin-bottom: ${theme.spacings.mega};
`;

const ProgressBarWrapper = styled('div')(progressBarWrapperStyles);

const stepContainerStyles = ({ visible }) => css`
  display: ${visible ? 'inline' : 'none'};
`;
const StepContainer = styled('div')(stepContainerStyles);

const headlineContainerStyles = ({ theme }: { theme?: Theme }) => css`
  margin-bottom: ${theme.spacings.mega};
`;
const HeadlineContainer = styled('div')(headlineContainerStyles);

const fieldsContainerStyles = ({
  theme,
  hasDisclaimer,
}: {
  theme?: Theme;
  hasDisclaimer: boolean;
}) => css`
  margin-bottom: ${hasDisclaimer ? 0 : theme.spacings.mega};
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-column-gap: ${theme.spacings.mega};
`;
const FieldsContainer = styled('div')(fieldsContainerStyles);

const buttonContainerStyles = ({
  theme,
  stackOnTablet,
}: {
  theme?: Theme;
  stackOnTablet: boolean;
}) => css`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding-top: ${theme.spacings.mega};
  justify-content: flex-end;
  gap: 1rem;
  ${theme.mq.untilKilo} {
    flex-direction: column-reverse;
  }
  ${theme.mq.kiloToMega} {
    flex-direction: column-reverse;
  }
  ${stackOnTablet &&
  css`
    ${theme.mq.kiloToMega} {
      flex-direction: row;
    }
  `}
`;
const ButtonContainer = styled('div')(buttonContainerStyles);

const previousButtonStyles = () => css`
  align-items: center;
`;

const PreviousButton = styled(Button)(previousButtonStyles);

const fieldWrapperStyles = ({ gridSpan }) => css`
  grid-column: ${gridSpan};
`;
const FieldWrapper = styled('div')(fieldWrapperStyles);

type KeyofLeadReceivers = keyof typeof LEAD_RECEIVERS_CONTENTFUL_OPTIONS;
export type LeadReceiversApi =
  (typeof LEAD_RECEIVERS_CONTENTFUL_OPTIONS)[KeyofLeadReceivers];

export interface MultiStepFormProps {
  id: string;
  name: string;
  steps: Step[];
  contentType: string;
  headline: string;
  subheadline: RichTextPropType;
  disclaimer: RichTextPropType;
  consentToSendSteoInformation: boolean;
  fields: Record<string, any>[];
  previousButtonTrackingId: string;
  nextButtonTrackingId: string;
  previousButtonOptimizelyFullStackClickEvents: string[];
  nextButtonOptimizelyFullStackClickEvents: string[];
  shouldTriggerDownload: boolean;
  headerAlignment: Alignment;
  headingContent: RichTextPropType;
  headingContentThankyou: RichTextPropType;
  contentThankyou: RichtextColumnProps;
  downloadUrl: string;
  downloadButtonLabel: string;
  downloadButtonTrackingId: string;
  downloadButtonOptimizelyFullStackClickEvents: string[];
  headingContentReturningVisitors: RichTextPropType;
  contentReturningVisitors: RichTextPropType;
  progressBarContent: RichTextPropType;
  departmentId: string;
  optionalLabel: string;
  requiredMessage: string;
  nextButtonLabel: string;
  previousButtonLabel: string;
  submitButtonLabel: string;
  stackOnTablet: boolean;
  leadReceiver: LeadReceiversApi;
  submitButtonTrackingId: string;
  submitButtonOptimizelyFullStackClickEvents: string[];
  queryParamsInHiddenFields: string[];
  hideFirstColumnOnMobile: boolean;
  shouldLoadInitialStep: boolean;
}

// There is a problem with using the existing form component
// partial validation cannot be easily added
// to retrieve field values we need a new prop in the state
// when adding value to state,
// and trying to intercept dispatch, it hangs often
// looks like there is an infinite loop
// also, form data is retrieved here and in cb widget using formdata
// instead of react state
// why not reimplementing it now?
// scope of the ticket and dates, components need to be adjusted
// TODO: create spike for addressing issues in form

const MultiStepForm = ({
  steps,
  departmentId,
  optionalLabel,
  requiredMessage,
  contentType,
  name,
  headline,
  subheadline,
  headerAlignment = ALIGNMENT.CENTER,
  nextButtonLabel = 'Next',
  previousButtonLabel = 'Previous',
  submitButtonLabel = 'Submit',
  stackOnTablet = false,
  headingContent,
  headingContentThankyou,
  contentThankyou,
  downloadUrl,
  downloadButtonLabel,
  downloadButtonTrackingId,
  downloadButtonOptimizelyFullStackClickEvents,
  headingContentReturningVisitors,
  contentReturningVisitors,
  leadReceiver = LEAD_RECEIVERS_CONTENTFUL_OPTIONS.TILLER,
  submitButtonOptimizelyFullStackClickEvents,
  nextButtonOptimizelyFullStackClickEvents,
  previousButtonOptimizelyFullStackClickEvents,
  queryParamsInHiddenFields,
  progressBarContent,
  previousButtonTrackingId,
  nextButtonTrackingId,
  submitButtonTrackingId,
  hideFirstColumnOnMobile,
  id,
  shouldLoadInitialStep = false,
}: MultiStepFormProps) => {
  const [state, dispatch] = useReducer(reducer, buildInitialState(steps));
  const [downloadStepJustPassed, setDownloadStepJustPassed] = useState(false);
  const [isReturningStep, setIsReturningStep] = useState(false);
  const { accessibilityLabels, locale } = useContext(SiteContext);

  useEffect(() => {
    getDataFromLocalStorage(id, dispatch);
  }, [id]);

  useEffect(() => {
    if (shouldLoadInitialStep) {
      dispatch(createAction(Actions.INITIAL));
    }
  }, [shouldLoadInitialStep]);

  useEffect(() => {
    if (
      state.fileDownloaded &&
      !downloadStepJustPassed &&
      !shouldLoadInitialStep
    ) {
      setIsReturningStep(true);
      if (state.stage !== Stages.RETURNING) {
        dispatch(createAction(Actions.RETURNING));
      }
    }
  }, [state, downloadStepJustPassed, shouldLoadInitialStep]);

  const currentStep = steps ? steps[state.current - 1] : undefined;

  const quizOnly =
    currentStep?.fields?.length === 1 &&
    currentStep?.fields[0]?.contentType === 'quizField';

  const finalNextButtonTrackingId =
    isEmpty(currentStep?.nextButtonTrackingId) ||
    currentStep?.nextButtonTrackingId === 'non.specified@tracking-id'
      ? nextButtonTrackingId
      : currentStep?.nextButtonTrackingId;

  const finalNextButtonOptimizelyFullStackClickEvents = isEmpty(
    currentStep?.nextButtonOptimizelyFullStackClickEvents,
  )
    ? nextButtonOptimizelyFullStackClickEvents
    : currentStep?.nextButtonOptimizelyFullStackClickEvents;

  const isDownloadStep =
    !isEmpty(downloadUrl) && currentStep.shouldTriggerDownload;

  const handleOnClickPrevious = useCallback(() => {
    dispatch(createAction(Actions.PREVIOUS));
    const finalPreviousButtonTrackingId =
      isEmpty(currentStep?.previousButtonTrackingId) ||
      currentStep?.previousButtonTrackingId === 'non.specified@tracking-id'
        ? previousButtonTrackingId
        : currentStep?.previousButtonTrackingId;

    const finalPreviousButtonOptimizelyFullStackClickEvents = isEmpty(
      currentStep?.previousButtonOptimizelyFullStackClickEvents,
    )
      ? previousButtonOptimizelyFullStackClickEvents
      : currentStep?.previousButtonOptimizelyFullStackClickEvents;
    trackStep(
      finalPreviousButtonTrackingId,
      'previous-step',
      id,
      name,
      contentType,
      currentStep,
      finalPreviousButtonOptimizelyFullStackClickEvents,
    );
  }, [
    contentType,
    currentStep,
    id,
    name,
    previousButtonOptimizelyFullStackClickEvents,
    previousButtonTrackingId,
  ]);

  const handleOnSubmit = useCallback(
    (event) => {
      // cancel send: only on last step with ajax call
      event.preventDefault();

      if (isDownloadStep) {
        openFileByUrl(downloadUrl);
        setDownloadStepJustPassed(true);
      }
      if (state.current === state.total) {
        submitForm(
          event.target,
          state,
          leadReceiver,
          dispatch,
          id,
          isDownloadStep,
          locale,
        );
      } else {
        submitStep(
          event.target,
          state,
          leadReceiver,
          dispatch,
          currentStep,
          isDownloadStep,
          id,
          name,
          contentType,
          finalNextButtonTrackingId,
          finalNextButtonOptimizelyFullStackClickEvents,
          locale,
        );
      }
    },
    [
      state,
      leadReceiver,
      currentStep,
      id,
      name,
      contentType,
      finalNextButtonTrackingId,
      finalNextButtonOptimizelyFullStackClickEvents,
      downloadUrl,
      isDownloadStep,
      locale,
    ],
  );

  const handleFieldOnChange = (event) => {
    const form = event.target.closest('form');

    if (quizOnly) {
      submitStep(
        form,
        state,
        leadReceiver,
        dispatch,
        currentStep,
        isDownloadStep,
        id,
        name,
        contentType,
        finalNextButtonTrackingId,
        finalNextButtonOptimizelyFullStackClickEvents,
        locale,
      );
    }
  };

  const finalProgressBarContent = useMemo(
    () => processProgressBarLabel(state, progressBarContent),
    [state, progressBarContent],
  );

  if (isEmpty(steps)) {
    return null;
  }

  const showDownloadButton =
    isReturningStep && !isEmpty(downloadUrl) && !isEmpty(downloadButtonLabel);

  const trackingContentEntry = {
    contentType,
    contentEntryName: name,
    contentEntryId: id,
  };

  return (
    <>
      <StyledSectionHeader
        headline={headline}
        content={subheadline}
        alignment={headerAlignment}
      />
      <Container
        stackOnTablet={stackOnTablet}
        data-elbcontext="component:multistep_form"
      >
        <ContentSection hideOnMobile={hideFirstColumnOnMobile}>
          {state.stage === Stages.THANKYOU && (
            <RichText
              richText={headingContentThankyou}
              renderNode={MultistepFormRichText.renderNode()}
              renderMark={MultistepFormRichText.renderMark}
            />
          )}
          {state.stage === Stages.RETURNING && (
            <RichText
              richText={headingContentReturningVisitors}
              renderNode={MultistepFormRichText.renderNode()}
              renderMark={MultistepFormRichText.renderMark}
            />
          )}
          {state.stage === Stages.NORMAL && (
            <RichText
              richText={
                isEmpty(currentStep?.headingContent)
                  ? headingContent
                  : currentStep.headingContent
              }
              renderNode={MultistepFormRichText.renderNode()}
              renderMark={MultistepFormRichText.renderMark}
            />
          )}
        </ContentSection>
        <FormSection stackOnTablet={stackOnTablet}>
          {state.stage === Stages.THANKYOU && (
            <ThankyouContainer>
              <RichText
                richText={contentThankyou}
                renderNode={MultistepFormRichText.renderNode()}
                renderMark={MultistepFormRichText.renderMark}
              />
            </ThankyouContainer>
          )}
          {state.stage === Stages.RETURNING && (
            <ThankyouContainer>
              <RichText
                richText={contentReturningVisitors}
                renderNode={MultistepFormRichText.renderNode()}
                renderMark={MultistepFormRichText.renderMark}
              />
              {showDownloadButton && (
                <Link
                  href={downloadUrl}
                  target="_blank"
                  trackingId={downloadButtonTrackingId}
                  optimizelyFullStackClickEvents={
                    downloadButtonOptimizelyFullStackClickEvents
                  }
                  trackingContentEntry={trackingContentEntry}
                >
                  <Button
                    type="button"
                    variant="primary"
                    data-selector={dataSelector(
                      'download_button',
                      DATA_SELECTOR,
                    )}
                  >
                    {downloadButtonLabel}
                  </Button>
                </Link>
              )}
            </ThankyouContainer>
          )}
          {state.stage === Stages.NORMAL && (
            <>
              <ProgressBarWrapper>
                <StyledProgressBar
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  value={state.current}
                  max={state.total}
                  label={accessibilityLabels.progressBar}
                  hideLabel
                />
                <RichText
                  richText={finalProgressBarContent}
                  renderNode={MultistepFormRichText.renderNode()}
                  renderMark={MultistepFormRichText.renderMark}
                />
              </ProgressBarWrapper>

              {steps.map((step, stepIndex) => (
                <StepContainer
                  key={`${stepIndex}`}
                  visible={
                    state.loadingFromLocalStorageComplete &&
                    state.current === stepIndex + 1
                  }
                >
                  <HeadlineContainer>{step.headline}</HeadlineContainer>
                  {step.subheadline && (
                    <RichText
                      richText={step.subheadline}
                      renderNode={MultistepFormRichText.renderNode()}
                      renderMark={MultistepFormRichText.renderMark}
                    />
                  )}
                  <Form
                    id={id}
                    lightVersion={stepIndex + 1 < state.total}
                    fields={step.fields}
                    submitButtonTrackingId={submitButtonTrackingId}
                    submitButtonOptimizelyFullStackClickEvents={
                      submitButtonOptimizelyFullStackClickEvents
                    }
                    queryParamsInHiddenFields={queryParamsInHiddenFields}
                    departmentId={departmentId}
                    optionalLabel={optionalLabel}
                    requiredMessage={requiredMessage}
                    onSubmit={handleOnSubmit}
                    initialValues={state.form}
                  >
                    {(fieldsToRender) => (
                      <>
                        <FieldsContainer hasDisclaimer={!!step.disclaimer}>
                          {fieldsToRender.map(
                            ({ Field, props: fieldProps }, idx) => (
                              <FieldWrapper
                                gridSpan={getSpanFromFieldWidth(
                                  fieldProps.width,
                                )}
                                key={`${idx}`}
                              >
                                <Field
                                  {...fieldProps}
                                  onChange={handleFieldOnChange}
                                />
                              </FieldWrapper>
                            ),
                          )}
                        </FieldsContainer>
                        {step.disclaimer && (
                          <RichText
                            richText={step.disclaimer}
                            renderNode={MultistepFormRichText.renderNode()}
                            renderMark={MultistepFormRichText.renderMark}
                          />
                        )}
                        <ButtonContainer stackOnTablet={stackOnTablet}>
                          {state.current > 1 && (
                            <PreviousButton
                              variant="tertiary"
                              type="button"
                              data-selector={dataSelector(
                                'previous_step_button',
                                DATA_SELECTOR,
                              )}
                              onClick={handleOnClickPrevious}
                            >
                              {step?.previousButtonLabel || previousButtonLabel}
                            </PreviousButton>
                          )}
                          {state.current <= state.total && !quizOnly && (
                            <>
                              {step.shouldTriggerDownload && downloadUrl ? (
                                <Button
                                  type="submit"
                                  variant="primary"
                                  data-selector={dataSelector(
                                    'next_step_button',
                                    DATA_SELECTOR,
                                  )}
                                  isLoading={state.isLoading}
                                  loadingLabel="Loading"
                                >
                                  {state.current === state.total
                                    ? submitButtonLabel
                                    : step?.nextButtonLabel || nextButtonLabel}
                                </Button>
                              ) : (
                                <Button
                                  type="submit"
                                  variant="primary"
                                  data-selector={dataSelector(
                                    'next_step_button',
                                    DATA_SELECTOR,
                                  )}
                                  isLoading={state.isLoading}
                                  loadingLabel="Loading"
                                >
                                  {state.current === state.total
                                    ? submitButtonLabel
                                    : step?.nextButtonLabel || nextButtonLabel}
                                </Button>
                              )}
                            </>
                          )}
                        </ButtonContainer>
                      </>
                    )}
                  </Form>
                </StepContainer>
              ))}
            </>
          )}
        </FormSection>
      </Container>
    </>
  );
};

export default MultiStepForm;
